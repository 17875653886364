import _ from 'lodash'

export const getYears = (total: number): number[] => {
  let thisyear = new Date().getFullYear()
  let years = []

  for (let x = 0; x <= total; x++) {
    years.push(thisyear - x)
  }
  return years
}

export function getCurrentStage(stages: Array<any>, pathname: string) {
  const current = pathname.split(/[?#]/)[0]

  const stage = _.find(stages, (val: any) => {
    return current.indexOf(val.path) > -1 ? true : false
  })

  return stage
}

export function getNextStage(stages: Array<any>, cStage: any): any {
  const idx = _.findIndex(stages, (val: any) => {
    return val.stageSlug === cStage.stageSlug
  })

  return stages[idx + 1]
}

export function evalFormula(expression: string, value: number) {
  return eval(expression)
}

export function getParamValue(url: string, paramName: string) {
  let result = null
  let tmp = []
  url
    .substr(1)
    .split('&')
    .forEach(function (item) {
      tmp = item.split('=')
      if (tmp[0] === paramName) result = decodeURIComponent(tmp[1])
    })

  return result
}

export function getContrastColor(hexColor: string): string {
  var r = parseInt(hexColor.substring(1, 3), 16)
  var g = parseInt(hexColor.substring(3, 5), 16)
  var b = parseInt(hexColor.substring(5, 7), 16)
  var yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? '#000000' : '#FFFFFF'
}
