/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import useMutationApi from '../../../../hooks/useMutation'
import {SIGNUP_MUTATION} from '../../../GraphQLQueries/Auth'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {useDispatch} from 'react-redux'
import {actionsUsers} from '../../../../setup/redux/rootReducer'

const initialValues = {
  firstName: '',
  lastName: '',
  businessEmail: '',
  companyLocation: '',
  companyName: '',
  jobTitle: '',
}

const registrationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('First Name is required!'),
  lastName: Yup.string()
    .min(1, 'Minimum 1 character')
    .max(50, 'Maximum 50 characters')
    .required('Last Name is required!'),
  businessEmail: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Email is required!'),
  companyName: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Company Name is required!'),
  companyLocation: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Company Location is required!'),
  jobTitle: Yup.string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('Job Title is required!'),
})

export function Registration() {
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {createData, /* loading, */ error, data} = useMutationApi(SIGNUP_MUTATION)

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoader(true)
      try {
        await createData({
          firstName: values.firstName,
          lastName: values.lastName,
          businessEmail: values.businessEmail,
          companyLocation: values.companyLocation,
          companyName: values.companyName,
          jobTitle: values.jobTitle,
        })

        setLoader(false)
      } catch (error: any) {
        console.error(error)
        setStatus(
          error?.networkError?.result?.errors[0]?.message || 'The registration details is incorrect'
        )
        setSubmitting(false)
        setLoader(false)
      }
    },
  })

  useEffect(() => {
    if (data) {
      toast.success('Registered Successfully')
      dispatch(actionsUsers.setSignUp(true))
      // console.log('registration data', data?.registerUser)
      // alert(data?.registerUser)
      // navigate(`/auth/verify-user`)
      // setModalState(true)
      // setstatus(false)
    }
    if (error && error.networkError?.result?.errors[0]?.message) {
      toast.error(error.networkError?.result?.errors[0]?.message)
    }
  }, [data, error])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3 form-heading'>Create new account</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-7'>
        <input
          placeholder='First name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstName')}
          className={clsx(
            'form-control',
            {
              'is-invalid': formik.touched.firstName && formik.errors.firstName,
            },
            {
              'is-valid': formik.touched.firstName && !formik.errors.firstName,
            }
          )}
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Lastname */}
      <div className='fv-row mb-7'>
        <input
          placeholder='Last name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastName')}
          className={clsx(
            'form-control ',
            {
              'is-invalid': formik.touched.lastName && formik.errors.lastName,
            },
            {
              'is-valid': formik.touched.lastName && !formik.errors.lastName,
            }
          )}
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <input
          placeholder='Business Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('businessEmail')}
          className={clsx(
            'form-control',
            {'is-invalid': formik.touched.businessEmail && formik.errors.businessEmail},
            {
              'is-valid': formik.touched.businessEmail && !formik.errors.businessEmail,
            }
          )}
        />
        {formik.touched.businessEmail && formik.errors.businessEmail && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.businessEmail}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Companyname */}
      <div className='fv-row mb-7'>
        <input
          placeholder='Company Name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('companyName')}
          className={clsx(
            'form-control',
            {
              'is-invalid': formik.touched.companyName && formik.errors.companyName,
            },
            {
              'is-valid': formik.touched.companyName && !formik.errors.companyName,
            }
          )}
        />
        {formik.touched.companyName && formik.errors.companyName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.companyName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Companylocation */}
      <div className='fv-row mb-7'>
        <input
          placeholder='Company Location'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('companyLocation')}
          className={clsx(
            'form-control',
            {
              'is-invalid': formik.touched.companyLocation && formik.errors.companyLocation,
            },
            {
              'is-valid': formik.touched.companyLocation && !formik.errors.companyLocation,
            }
          )}
        />
        {formik.touched.companyLocation && formik.errors.companyLocation && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.companyLocation}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group jobTitle */}
      <div className='fv-row mb-7'>
        <input
          placeholder='Job Title'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('jobTitle')}
          className={clsx(
            'form-control',
            {
              'is-invalid': formik.touched.jobTitle && formik.errors.jobTitle,
            },
            {
              'is-valid': formik.touched.jobTitle && !formik.errors.jobTitle,
            }
          )}
        />
        {formik.touched.jobTitle && formik.errors.jobTitle && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.jobTitle}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
        >
          {!loader && <span className='indicator-label'>Sign Up</span>}
          {loader && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}
