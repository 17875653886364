import {gql} from '@apollo/client'

export const GET_INDUSTRY_LIST = gql`
  query getIndustryList($limit: Int!) {
    getIndustryList(limit: $limit) {
      id
      industryCategory
      industryDescription
      industryIcon
      industryName
    }
  }
`

export const ADD_USER_INDUSTRY_MUTATION = gql`
  mutation addUserIndustry($id: ID!) {
    addUserIndustry(id: $id)
  }
`
