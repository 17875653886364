import {gql} from '@apollo/client'

export const GET_CALCULATOR_STAGES = gql`
  query getCarbonCalcStage {
    getCarbonCalcStage {
      stageDetail {
        id
        isActive
        path
        stageName
        stageOrder
        stageSlug
        stageVariables
        isStageDataFilled
      }
      id
      industryId
    }
  }
`

export const UPSERT_CALCULATOR_STAGE_MUTATION = gql`
  mutation addOrUpdateCarCalcStageData($stageSlug: String!, $stageVariablesData: JSON!) {
    addOrUpdateCarCalcStageData(
      carCalInp: {stageSlug: $stageSlug, stageVariablesData: $stageVariablesData}
    )
  }
`

export const GET_CALCULATOR_STAGE_DATA = gql`
  query getCarbonCalcStageData($stageSlug: String!) {
    getCarbonCalcStageData(stageSlug: $stageSlug) {
      id
      stageData {
        id
        stageVariablesData
        stageSlug
        stageOrder
        stageName
        path
      }
      totalCarbonEmission
    }
  }
`

export const GET_CALCULATOR_SUMMARY = gql`
  query getCarbonCalcSummery {
    getCarbonCalcSummery {
      summaryData
      totalCarbonEmission
    }
  }
`
