/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import '../../../../App.css'
const NotificationMessenger: FC = () => (
  <div
    id='kt_drawer_notification'
    className='bg-body'
    data-kt-drawer='true'
    data-kt-drawer-name='notification'
    data-kt-drawer-activate='true'
    data-kt-drawer-overlay='true'
    data-kt-drawer-width="{default:'300px', 'md': '500px'}"
    data-kt-drawer-direction='end'
    data-kt-drawer-toggle='#kt_drawer_notification_toggle'
    data-kt-drawer-close='#kt_drawer_notification_close'
  >
    <div className='col-12'>
      <div className=' card w-100 rounded-0' id='kt_drawer_chat_messenger'>
        <div
          className='d-flex flex-direction-row justify-content-between align-items-center mx-5 mt-5 border-bottom'
          id='kt_drawer_chat_messenger_header'
        >
          {/* <div className='card-title'> */}
          <div className='d-flex justify-content-center flex-column me-3'>
            <a href='#' className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1  lh-1'>
              Notifications
            </a>
          </div>
          {/* </div> */}

          <div className='card-toolbar'>
            <div
              className='btn btn-sm btn-icon btn-active-light-dark'
              id='kt_drawer_notification_close'
            >
              <KTSVG path='/media/ePulse/cross.svg' className='svg-icon-1' />
            </div>
          </div>
        </div>
      </div>
      {/* <div className='mx-5 mt-5'>
        <span>Monday, Dec 10,2022</span>
        <div className='d-flex p-3 border rounded my-2 align-items-center shadow'>
          <div className='symbol symbol-45px me-5'>
            <img src={toAbsoluteUrl('/media/ePulse/doctor.jpg')} alt='' />
          </div>
          <div className='d-flex flex-direction-row justify-content-between align-items-center'>
            <div className='col-9 '>
              You scheduled a Appointment with Rahul Sharma for today at 5 PM.
            </div>
            <div className='d-flex justify-content-center flex-shrink-0'>
              <a href='#' className='text-primary fs-7 fw-bold'>View</a >
            </div>
          </div>
        </div>
        <div className='d-flex p-3 border rounded my-2 align-items-center shadow'>
          <div className='symbol symbol-45px me-5'>
            <img src={toAbsoluteUrl('/media/ePulse/medical_team.jpg')} alt='' />
          </div>
          <div className='d-flex flex-direction-row justify-content-between align-items-center'>
            <div className='col-9 '>
            Connect with your clinics for better assistance with your patients
            </div>
            <div className='d-flex justify-content-center flex-shrink-0'>
              <a href='#' className='text-primary fs-7 fw-bold'>View</a >
            </div>
          </div>
        </div>
      </div>
      <div className='mx-5 mt-5'>
        <span>Monday, Dec 10,2022</span>
        <div className='d-flex p-3 border rounded my-2 align-items-center shadow'>
          <div className='symbol symbol-45px me-5'>
            <img src={toAbsoluteUrl('/media/ePulse/doctor.jpg')} alt='' />
          </div>
          <div className='d-flex flex-direction-row justify-content-between align-items-center'>
            <div className='col-9 '>
              You scheduled a Appointment with Rahul Sharma for today at 5 PM.
            </div>
            <div className='d-flex justify-content-center flex-shrink-0'>
              <a href='#' className='text-primary fs-7 fw-bold'>View</a >
            </div>
          </div>
        </div>
        <div className='d-flex p-3 border rounded my-2 align-items-center shadow'>
          <div className='symbol symbol-45px me-5'>
            <img src={toAbsoluteUrl('/media/ePulse/medical_team.jpg')} alt='' />
          </div>
          <div className='d-flex flex-direction-row justify-content-between align-items-center'>
            <div className='col-9 '>
            Connect with your clinics for better assistance with your patients
            </div>
            <div className='d-flex justify-content-center flex-shrink-0'>
              <a href='#' className='text-primary fs-7 fw-bold'>View</a >
            </div>
          </div>
        </div>
      </div> */}
    </div>
  </div>
)

export {NotificationMessenger}
