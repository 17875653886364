// useApi.js

import {useQuery} from '@apollo/client'
import {useDispatch} from 'react-redux'
import {actionsUsers} from '../setup/redux/rootReducer'

const useApi = (query: any, variables: any) => {
  const dispatch = useDispatch()
  const {loading, error, data, refetch}: any = useQuery(query, {variables, fetchPolicy: 'no-cache'})

  if (error && error?.networkError?.result?.errors[0]?.extensions?.code === 'SESSION_EXPIRED') {
    dispatch(actionsUsers.logout())
  }

  return {
    loading,
    error,
    data,
    refetch,
  }
}

export default useApi
