/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import {actionsCompany} from '../../../../setup/redux/reducers/companyReducer'
import {toAbsoluteUrl} from '../../../../_ePulse/helpers'
import useApi from '../../../../hooks/useQuery'
import {
  ADD_USER_INDUSTRY_MUTATION,
  GET_INDUSTRY_LIST,
} from '../../../GraphQLQueries/InduustryDomain'
import useMutationApi from '../../../../hooks/useMutation'
import {toast} from 'react-toastify'
import _ from 'lodash'

const companySchema = Yup.object().shape({
  industryDomain: Yup.string().required('Industry Domain is required'),
})

const initialValues = {
  industryDomain: '',
}

const domainData: any = {
  'Import, Warehouse & Distribution': {
    icon: 'import',
  },
  'Food Processing': {
    icon: 'food',
  },
}

export function DomainModel(props: any) {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const {modalState} = props

  const {
    data: industryList,
    loading: domainLoading,
    error: domainError,
    refetch: domainReFetch,
  } = useApi(GET_INDUSTRY_LIST, {
    limit: 10,
  })

  const {
    createData: addUserIndustry,
    loading: addDomainLoading,
    error: addDomainError,
    data: addDomainRes,
  } = useMutationApi(ADD_USER_INDUSTRY_MUTATION)

  useEffect(() => {
    if (industryList?.getIndustryList) {
    }
    console.log(industryList, 'industryList')
  }, [industryList])

  const formik = useFormik({
    initialValues,
    validationSchema: companySchema,
    onSubmit: async (values) => {
      setLoader(true)
      try {
        const industryDomain = values.industryDomain
        if (industryDomain && industryList && industryList.getIndustryList) {
          let indusObj = _.find(
            industryList.getIndustryList,
            (v) => String(v.id) === String(industryDomain)
          )

          await addUserIndustry({
            id: industryDomain,
          })

          dispatch(actionsCompany.updateDomain(indusObj))
          // props.closeModel()
        }

        setLoader(false)
      } catch (error) {
        setLoader(false)
      }
    },
  })

  useEffect(() => {
    if (domainError) {
      toast.error(domainError?.networkError?.result?.errors[0]?.message || domainError?.message)
    }
  }, [domainError])

  useEffect(() => {
    if (addDomainRes && addDomainRes.addUserIndustry) {
      toast.success(addDomainRes.addUserIndustry || 'Industry Domain Saved Successfully')
      props.closeModel()
    } else if (addDomainError?.networkError?.result?.errors[0]?.message) {
      toast.error(
        addDomainError?.networkError?.result?.errors[0]?.message /*  || addDomainError?.message */
      )
    }
  }, [addDomainError, addDomainRes])

  return (
    <div>
      <Modal
        show={modalState}
        dialogClassName='ePulse-modal ePulse-modal-40w custom-modal-width modal-dialog-centered'
        onHide={() => props.closeModel()}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Body className='container' />
        <div className='ePulse-modal-content'>
          <form onSubmit={formik.handleSubmit}>
            <div className='custom-modal d-flex'>
              <h2 className='modal-heading'>Choose Your Industry Domain </h2>
              <div className='d-grid gap-4 col-12 mx-auto my-5'>
                {industryList?.getIndustryList &&
                  industryList?.getIndustryList.map((val: any, idx: any) => (
                    <div className='choose-industry-btn d-flex justify-content-between'>
                      <label className='custom-radio-btn' htmlFor={val.id}>
                        <img
                          src={toAbsoluteUrl(
                            `/images/${domainData[val.industryCategory].icon}.svg`
                          )}
                          alt=''
                          className='w-30px me-3'
                        />
                        {val.industryName}
                      </label>
                      <input
                        type='radio'
                        {...formik.getFieldProps('industryDomain')}
                        value={val.id}
                        id={val.id}
                        className='px-3'
                      />
                    </div>
                  ))}

                <button
                  className='btn main-btn mt-5'
                  type='submit'
                  disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
                >
                  {!loader && (
                    <span className='indicator-label'>Start Carbon Emission Calculation</span>
                  )}
                  {loader && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...{' '}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  )
}
