/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {actionsUsers} from '../../../../setup/redux/rootReducer'
import {useMutation} from '@apollo/client'
import {LOGOUT} from '../../../../app/GraphQLQueries/Auth'
import useMutationApi from '../../../../hooks/useMutation'
const HeaderUserMenu: FC = () => {
  const dispatch = useDispatch()

  const {createData, loading: loading2, error, data} = useMutationApi(LOGOUT)

  // const [logoutSession, {data, error}] = useMutation(LOGOUT, {
  //   onError: (error: any) => {
  //     // if (error?.networkError?.statusCode === 440) {
  //       dispatch(actionsUsers.logout())
  //     // }
  //   },

  //   onCompleted: (data: any) => {
  //     dispatch(actionsUsers.logout())
  //   },
  // })

  const logout = async () => {
    // await logoutSession({
    //   variables: {
    //     logoutType: 'one',
    //   },
    // })
    const response = await createData({logoutType: 'one'})

    dispatch(actionsUsers.logout())
  }

  useEffect(() => {
    // if (error) {
    //   console.log('ERRORADDADMIN.......', error)
    // }
  }, [])
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {/* <div className='menu-item px-5'>
        <Link to={'/updatePassword'} className='menu-link px-5 text-black fw-normal'>
          Update Password
        </Link>
      </div>
      <div className='separator my-2 mx-8'></div> */}

      <div className='menu-item px-5'>
        <a onClick={() => logout()} className='menu-link px-5 text-black fw-normal'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
