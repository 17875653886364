import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import '../../../../App.css'
import {useQuery, gql, useMutation} from '@apollo/client'
import {FORGOT_PASSWORD_MUTATION} from '../../../GraphQLQueries/Auth'
import useMutationApi from '../../../../hooks/useMutation'
import {toast} from 'react-toastify'
import {getParamValue} from '../../../../data/common'
const initialValues = {
  // token: '',
  newPassword: '',
  confirmPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  // token: Yup.string().required('Token is required'),
  newPassword: Yup.string().required('Password is required'),
  confirmPassword: Yup.string().required('Confirm Password is required'),
})

export function NewPassword() {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [hasErrorsmsg, setHasErrorsmsg] = useState<any>('')
  const location = useLocation()
  // const {email}: any = location?.state

  const {createData, error, data} = useMutationApi(FORGOT_PASSWORD_MUTATION)

  useEffect(() => {
    if (data) {
      // console.log(data, 'datattattta')
      toast.success(data?.forgetPassword /* 'Successfully reset Password' */)
      navigate(`/auth`)
    }
    if (error && error.networkError?.result?.errors[0]?.message) {
      toast.error(error.networkError?.result?.errors[0]?.message)
    }
  }, [data, error])

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoader(true)

      if (values.newPassword !== values.confirmPassword) {
        setStatus('Password & Confirm Password are not same')
        setLoader(false)
      } else {
        try {
          // const email = new URLSearchParams(window.location.search).get('email')
          // const token = new URLSearchParams(window.location.search).get('token')
          const email = getParamValue(window.location.search, 'email')
          const token = window.location.search.split('token=')[1]

          await createData({
            email: email,
            token: token,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword,
          })
          setLoader(false)
        } catch (error: any) {
          console.log('ERROR......', error)
          setSubmitting(false)
          setLoader(false)
          setStatus(error?.networkError?.result?.errors[0]?.message || error?.message)
        }
      }
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3 form-heading'>Enter New Password</h1>
          {/* end::Title */}
        </div>

        {/* begin::Title */}
        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          {/* <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Token</label>
            <input
              placeholder=''
              {...formik.getFieldProps('token')}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
            {formik.touched.token && formik.errors.token && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.token}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
            <input
              type='password'
              placeholder=''
              {...formik.getFieldProps('newPassword')}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.newPassword}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
            <input
              type='password'
              placeholder=''
              {...formik.getFieldProps('confirmPassword')}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-column justify-content-center align-items-center '>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg  w-100 mb-5'
            disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
          >
            {!loader && <span className='indicator-label'>Update Password</span>}
            {loader && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
