import {gql} from '@apollo/client'

export const GET_COMPANY_PROFILE = gql`
  query getCompanyProfile {
    getCompanyProfile {
      assessmentYear
      businessDescription
      carbonOffsetGoals
      companyLocation
      annualProductionRate
      companyName
      inNumberOfYear
      products
      visionMission
    }
  }
`

export const UPSERT_COMPANY_MUTATION = gql`
  mutation createOrUpdateCompanyProfile(
    $companyName: String!
    $companyLocation: String
    $annualProductionRate: String
    $assessmentYear: String!
    $businessDescription: String
    $visionMission: String
    $products: [String]
    $carbonOffsetGoals: Float
    $inNumberOfYear: Float
  ) {
    createOrUpdateCompanyProfile(
      compProfInp: {
        companyName: $companyName
        companyLocation: $companyLocation
        annualProductionRate: $annualProductionRate
        assessmentYear: $assessmentYear
        businessDescription: $businessDescription
        visionMission: $visionMission
        products: $products
        carbonOffsetGoals: $carbonOffsetGoals
        inNumberOfYear: $inNumberOfYear
      }
    )
  }
`

export const GET_USER_CURRENT_INDUSTRY = gql`
  query getUserCurrentIndustry {
    getUserCurrentIndustry {
      industryId {
        id
        industryCategory
        industryDescription
        industryIcon
        industryName
      }
      id
      userId
    }
  }
`

export const RESET_COMPANY_MUTATION = gql`
  mutation resetCompanyData {
    resetCompanyData
  }
`
