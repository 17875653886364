import {useIntl} from 'react-intl'
import {Typography, Paper, Grid, Divider, List, ListItem, ListItemText} from '@mui/material'
import {PageTitle} from '../../_ePulse/layout/core'
import useApi from '../../hooks/useQuery'
import {GET_COMPANY_PROFILE} from '../GraphQLQueries/Company'
import {toast} from 'react-toastify'

const CompanyInfo = () => {
  const {
    data: companyProfile,
    loading: getCompanyLoading,
    error: getCompanyError,
  } = useApi(GET_COMPANY_PROFILE, {})

  if (getCompanyLoading) {
    return <h3>Loading...</h3>
  }

  if (getCompanyError) {
    toast.error(getCompanyError?.networkError?.result?.errors[0]?.message)
  }

  console.log('companyProfile: ', companyProfile.getCompanyProfile)

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3} style={{padding: '20px'}}>
          <Typography variant='h3'>Company Information</Typography>
          <Divider style={{margin: '10px 0'}} />
          {/* Company Details Section */}
          {/* <Typography variant='h4'>Company Details</Typography> */}
          <Typography variant='h6' className='mt-3'>
            Company Name: {companyProfile?.getCompanyProfile?.companyName}
          </Typography>

          {companyProfile?.getCompanyProfile?.companyLocation ? (
            <Typography variant='h6' className='mt-3'>
              Location : {companyProfile?.getCompanyProfile?.companyLocation}
            </Typography>
          ) : null}

          {companyProfile?.getCompanyProfile?.annualProductionRate ? (
            <Typography variant='h6' className='mt-3'>
              Annual Production Rate : {companyProfile?.getCompanyProfile?.annualProductionRate}
            </Typography>
          ) : null}

          <Typography variant='h6' className='mt-3'>
            Assessment Year: {companyProfile?.getCompanyProfile?.assessmentYear}
          </Typography>

          <Typography variant='h6' className='mt-3'>
            Business Description:
            {/* <Typography variant='body1'> */}
            {companyProfile?.getCompanyProfile?.businessDescription}
            {/* </Typography> */}
          </Typography>
          <Typography variant='h6' className='mt-3' style={{wordWrap: 'break-word'}}>
            Vision Mission: {companyProfile?.getCompanyProfile?.visionMission}
          </Typography>
          <Typography variant='h6' className='mt-3'>
            Products:
          </Typography>
          <List>
            {companyProfile?.getCompanyProfile?.products.map((product: any) => (
              <ListItem key={product}>
                <ListItemText primary={product} />
              </ListItem>
            ))}
          </List>

          <Typography variant='h6' className='mt-3'>
            Carbon Offset Goals: {(companyProfile?.getCompanyProfile?.carbonOffsetGoals || 0) + '%'}
          </Typography>

          <Typography variant='h6' className='mt-3'>
            In number of year: {companyProfile?.getCompanyProfile?.inNumberOfYear}
          </Typography>

          {/* Contact Information Section */}
          {/* <Typography variant='h4' style={{marginTop: '20px'}}>
            Contact Information
          </Typography> */}
        </Paper>
      </Grid>
    </Grid>
  )
}

const CompanyInfoPage = () => {
  const intl = useIntl()
  return (
    <>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.COMPANY_INFO'})}</PageTitle> */}
      <CompanyInfo />
    </>
  )
}

export {CompanyInfoPage}
