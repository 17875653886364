/* export const PRODUCT_LIST = [
  {
    label: 'Petroleum Oils',
    value: 'Petroleum Oils',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Crude Oil',
    value: 'Crude Oil',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Electronics',
    value: 'Electronics',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Computer and Peripherals',
    value: 'Computer and Peripherals',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Medical Equipments',
    value: 'Medical Equipments',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Meat, fish and Poultry',
    value: 'Meat, fish and Poultry',
    category: 'Food Processing',
  },
  {
    label: 'Grains and spices',
    value: 'Grains and spices',
    category: 'Food Processing',
  },
  {
    label: 'Bakery Products',
    value: 'Bakery Products',
    category: 'Food Processing',
  },
  {
    label: 'Dairy Products',
    value: 'Dairy Products',
    category: 'Food Processing',
  },
  {
    label: 'Beverages',
    value: 'Beverages',
    category: 'Food Processing',
  },
] */

export const PRODUCT_LIST = [
  {
    label: 'Animals',
    value: 'Animals',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Fats/Oils',
    value: 'Fats/Oils',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Food/Bev/Tobacco',
    value: 'Food/Bev/Tobacco',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Minerals',
    value: 'Minerals',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Chemicals',
    value: 'Chemicals',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Plastics/Rubber',
    value: 'Plastics/Rubber',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Leather/Travel Goods',
    value: 'Leather/Travel Goods',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Wood/Cork/Basket',
    value: 'Wood/Cork/Basket',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Paper/Pulp',
    value: 'Paper/Pulp',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Textiles',
    value: 'Textiles',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Footwear/Apparel/Accessories',
    value: 'Footwear/Apparel/Accessories',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Stone/Ceramics/Glass',
    value: 'Stone/Ceramics/Glass',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Metals',
    value: 'Metals',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Machinery/Electronics',
    value: 'Machinery/Electronics',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Instruments',
    value: 'Instruments',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Arms/Ammo',
    value: 'Arms/Ammo',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Miscellaneous',
    value: 'Miscellaneous',
    category: 'Import, Warehouse & Distribution',
  },
  {
    label: 'Art/Antiques',
    value: 'Art/Antiques',
    category: 'Import, Warehouse & Distribution',
  },

  {
    label: 'Meat, fish and poultry',
    value: 'Meat, fish and poultry',
    category: 'Food Processing',
  },
  {
    label: 'Grains and Spices',
    value: 'Grains and Spices',
    category: 'Food Processing',
  },
  {
    label: 'Margarine and cooking fats',
    value: 'Margarine and cooking fats',
    category: 'Food Processing',
  },
  {
    label: 'Bakery products',
    value: 'Bakery products',
    category: 'Food Processing',
  },
  {
    label: 'Animal feed',
    value: 'Animal feed',
    category: 'Food Processing',
  },
  {
    label: 'Beverages',
    value: 'Beverages',
    category: 'Food Processing',
  },
  {
    label: 'Dairy products',
    value: 'Dairy products',
    category: 'Food Processing',
  },
  {
    label: 'Other foods',
    value: 'Other foods',
    category: 'Food Processing',
  },
]

export const TABS_LIST = [
  {
    name: 'Recommendations',
    stageSlug: 'recommendations',
    classes: 'tab-menu-first',
    path: '/recommendations',
    key: 'hasSequestrationData',
  },
  {
    name: 'Year wise Plan',
    stageSlug: 'yearWise',
    classes: '',
    path: '/year-wise-plan',
    key: 'hasYearWisePlan',
  },
  {
    name: 'Carbon Credits',
    stageSlug: 'credits',
    classes: '',
    path: '/carbon-credits',
    key: 'hasCarbonCreditPurchase',
  },
  {
    name: 'Summary',
    stageSlug: 'summry',
    classes: 'tab-menu-last',
    path: '/summary',
    key: 'hasCarbonCreditPurchaseSummery',
  },
]

/* export const CALCULATOR_STEPS = [
  {
    id: 'atPort',
    name: 'At Port',
    classes: 'tab-menu-first',
    path: '/at-port',
    category: 'Import, Warehouse & Distribution',
  },
  {
    id: 'transit',
    name: 'Transit',
    classes: '',
    path: '/transit',
    category: 'Import, Warehouse & Distribution',
  },
  {
    id: 'atWarehouse',
    name: 'At Ware House',
    classes: '',
    path: '/at-warehouse',
    category: 'Import, Warehouse & Distribution',
  },
  {
    id: 'transfer',
    name: 'Transfer',
    classes: '',
    path: '/transfer',
    category: 'Import, Warehouse & Distribution',
  },
  {
    id: 'distributorWarehouse',
    name: 'Distributor Warehouse',
    classes: '',
    path: '/distributor-warehouse',
    category: 'Import, Warehouse & Distribution',
  },
  {
    id: 'summary',
    name: 'Summary',
    classes: 'tab-menu-last',
    path: '/iwd-summary',
    category: 'Import, Warehouse & Distribution',
  },
  {
    id: 'transferRawMaterial',
    name: 'Transfer Raw Material',
    classes: 'tab-menu-first',
    path: '/transfer-raw-material',
    category: 'Food Processing',
  },
  {
    id: 'foodMgfOffice',
    name: 'Food Mgf. Office',
    classes: '',
    path: '/food-mgf-office',
    category: 'Food Processing',
  },
  {
    id: 'processesAtPlant',
    name: 'Processes At Plant',
    classes: '',
    path: '/processes-at-plant',
    category: 'Food Processing',
  },
  {
    id: 'transferFinishedProduct',
    name: 'Transfer Finished Product',
    classes: '',
    path: '/transfer-finished-product',
    category: 'Food Processing',
  },
  {
    id: 'foodSummary',
    name: 'Summary',
    classes: 'tab-menu-last',
    path: '/food-summary',
    category: 'Food Processing',
  },
] */
