import {gql} from '@apollo/client'

export const LOGIN_MUTATION = gql`
  mutation loginUser($email: String!, $password: String!) {
    loginUser(loginInp: {email: $email, password: $password}) {
      id
      token
      tokenExpirationTime
      firstName
      lastName
      companyName
      userStatus
      businessEmail
      isIndustrySelected
      isCompanyProfileCompleted
    }
  }
`

export const SIGNUP_MUTATION = gql`
  mutation registerUser(
    $firstName: String!
    $lastName: String!
    $businessEmail: String!
    $companyLocation: String!
    $companyName: String!
    $jobTitle: String!
  ) {
    registerUser(
      regInput: {
        firstName: $firstName
        lastName: $lastName
        businessEmail: $businessEmail
        companyLocation: $companyLocation
        companyName: $companyName
        jobTitle: $jobTitle
      }
    )
  }
`

export const VERIFY_USER_MUTATION = gql`
  mutation verifyEmail($token: ID!, $password: String!, $confirmPassword: String!) {
    verifyEmail(verifyInp: {token: $token, password: $password, confirmPassword: $confirmPassword})
  }
`

export const SEND_FORGOT_PASSWORD_LINK_MUTATION = gql`
  mutation sendForgerPasswordLink($email: String!) {
    sendForgerPasswordLink(email: $email)
  }
`

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgetPassword(
    $email: String!
    $token: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    forgetPassword(
      fPwdInp: {
        email: $email
        token: $token
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      }
    )
  }
`

// OLD ONE

export const OTPVERIFY_MUTATION = gql`
  mutation verifyAdminOtp(
    $email: String!
    $countryCode: Int!
    $mobileNumber: MobileNumber!
    $otp: Int!
    $browserName: String
    $browserUniqueId: String!
    $ipAddress: String
    $userAgent: String
  ) {
    verifyAdminOtp(
      adminOtpInput: {
        email: $email
        countryCode: $countryCode
        mobileNumber: $mobileNumber
        otp: $otp
        deviceDetail: {
          browserName: $browserName
          browserUniqueId: $browserUniqueId
          ipAddress: $ipAddress
          userAgent: $userAgent
        }
      }
    ) {
      userId
      token
      tokenExpiration
      userProfile {
        firstName
        lastName
        gender
        dob
        profilePhoto
        preferredLanguage
      }
      userPermissions
    }
  }
`

export const UPDATE_ADMIN_PASSWORD = gql`
  mutation updateAdminPassword(
    $oldPassword: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    updateAdminPassword(
      userInput: {
        oldPassword: $oldPassword
        newPassword: $newPassword
        confirmPassword: $confirmPassword
      }
    )
  }
`

export const LOGOUT = gql`
  mutation logoutSession($logoutType: logoutType!) {
    logoutSession(logoutType: $logoutType)
  }
`
