import {FieldProps} from 'formik'
import React from 'react'
import Select from 'react-select'

type OptionType = {label: string; value: string}
type OptionsType = Array<OptionType>

type ValueType = OptionType | OptionsType | null | void

interface CustomSelectProps extends FieldProps {
  options: OptionsType
  isMulti?: boolean
  className?: string
  placeholder?: string
}

export const MultiSelect = ({
  className,
  placeholder,
  field,
  form,
  options,
  isMulti = false,
}: CustomSelectProps) => {
  const onChange = (option: ValueType) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as OptionsType).map((item: OptionType) => item.value)
        : (option as OptionType).value
    )
  }

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option: any) => field.value.indexOf(option.value) >= 0)
        : options.find((option: any) => option.value === field.value)
    } else {
      return isMulti ? [] : ('' as any)
    }
  }

  return (
    <Select
      className={className}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      // styles={{
      //   control: (baseStyles, state) => ({
      //     ...baseStyles,
      //     borderColor: state.isFocused ? '#00a84d' : '#e4e6ef',
      //   }),
      // }}
    />
  )
}

export default MultiSelect
