import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  industryDomain: null,
  company: null,
}

const companyReducer = createSlice({
  name: 'company',
  initialState,
  reducers: {
    updateDomain: (state, action) => {
      state.industryDomain = action.payload
    },
    updateCompany: (state, action) => {
      state.company = action.payload
    },

    reset: () => ({...initialState}),
  },
})

export const actionsCompany = companyReducer.actions
export default companyReducer.reducer
