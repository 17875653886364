/* eslint-disable react/jsx-no-target-blank */
import {FC, useEffect, useState} from 'react'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
// import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {WithChildren} from '../../../helpers'
import {useDispatch, useSelector} from 'react-redux'
// import {CALCULATOR_STEPS} from '../../../../data'
import _ from 'lodash'
import useApi from '../../../../hooks/useQuery'
import {GET_CALCULATOR_STAGES} from '../../../../app/GraphQLQueries/Calculator'
import {toast} from 'react-toastify'
import {actionsCalculator} from '../../../../setup/redux/reducers/calculatorReducer'

type Props = {
  isMinified: boolean
}

const AsideMenuMain: FC<Props & WithChildren> = ({isMinified}) => {
  // const intl = useIntl()
  const dispatch = useDispatch()
  // const [calculatorMenus, setCalculatorMenus] = useState<any>([])

  const {calculator}: any = useSelector((state) => {
    return state
  })

  // useEffect(() => {
  //   setCalculatorMenus(calculator.stages)
  // }, [calculator.stages])

  const {
    data: calculatorStages,
    loading: getStagesLoading,
    error: getStagesError,
    refetch: getStagesReFetch,
  } = useApi(GET_CALCULATOR_STAGES, {})

  useEffect(() => {
    if (
      calculatorStages &&
      calculatorStages.getCarbonCalcStage &&
      calculatorStages.getCarbonCalcStage.stageDetail &&
      !(calculator && calculator.stages)
    ) {
      // setCalculatorMenus(calculatorStages.getCarbonCalcStage.stageDetail)
      dispatch(actionsCalculator.updateStages(calculatorStages.getCarbonCalcStage.stageDetail))
    } else if (getStagesError?.networkError?.result?.errors[0]?.message) {
      toast.error(getStagesError?.networkError?.result?.errors[0]?.message)
    }
  }, [getStagesError, calculatorStages])

  // useEffect(() => {
  //   if (CALCULATOR_STEPS && company && company.industryDomain) {
  //     let calMenusFiltered = _.filter(
  //       CALCULATOR_STEPS,
  //       (v) => v.category === company.industryDomain.industryCategory
  //     )

  //     setCalculatorMenus(calMenusFiltered)
  //   }
  // }, [company?.industryDomain])

  return (
    <>
      {/* <AsideMenuItem
        to='/dashboard'
        title='Dashboard'
        icon='/media/ePulse/icons/aside/user.svg'
        heading='Dashboard'
        // className='py-3'
      ></AsideMenuItem> */}

      <AsideMenuItemWithSubMain
        to='/calculator'
        title='Carbon Calculator'
        icon='/media/ePulse/icons/aside/calculator.svg'
        heading='Carbon Calculator'
        isMinified={isMinified}
      >
        {calculator &&
          calculator.stages &&
          calculator.stages.map((val: any, idx: any) => (
            <AsideMenuItem
              key={'cal_menu_' + idx}
              isMinified={isMinified}
              to={'/calculator' + val.path}
              title={val.stageName}
            />
          ))}
        {/* <AsideMenuItem isMinified={isMinified} to='/calculator/at-port' title='At Port' />
        <AsideMenuItem isMinified={isMinified} to='/calculator/transit' title='Transit' />
        <AsideMenuItem isMinified={isMinified} to='/calculator/at-warehouse' title='At Warehouse' />
        <AsideMenuItem isMinified={isMinified} to='/calculator/transfer' title='Transfer' />
        <AsideMenuItem
          isMinified={isMinified}
          to='/calculator/distributor-warehouse'
          title='Distributor Warehouse'
        />
        <AsideMenuItem isMinified={isMinified} to='/calculator/summary' title='Summary' /> */}
      </AsideMenuItemWithSubMain>

      <AsideMenuItemWithSubMain
        to='/offset'
        title='Carbon Offset'
        icon='/media/ePulse/icons/aside/offset.svg'
        heading='Carbon Offset'
        isMinified={isMinified}
      >
        <AsideMenuItem
          isMinified={isMinified}
          to='/offset/recommendations'
          title='Recommendations'
        />
        <AsideMenuItem isMinified={isMinified} to='/offset/year-wise-plan' title='Year wise Plan' />
        <AsideMenuItem isMinified={isMinified} to='/offset/carbon-credits' title='Carbon Credits' />
        <AsideMenuItem isMinified={isMinified} to='/offset/summary' title='Summary' />
      </AsideMenuItemWithSubMain>

      {/* <AsideMenuItem
        to='/offset/summary'
        title='Reports'
        icon='/media/ePulse/icons/aside/reports.svg'
        heading='Reports'
        // className='py-3'
      ></AsideMenuItem> */}

      <AsideMenuItemWithSubMain
        to='/settings'
        title='Settings'
        icon='/media/ePulse/icons/aside/setting.svg'
        heading='Settings'
        isMinified={isMinified}
      >
        <AsideMenuItem isMinified={isMinified} to='/company-info' title='Company Information' />
        <AsideMenuItem isMinified={isMinified} to='/reset-account' title='Reset Account' />
        {/* <AsideMenuItem
          isMinified={isMinified}
          to='/notification-settings'
          title='Notification Settings'
        /> */}
        <AsideMenuItem isMinified={isMinified} to='/faqs' title='FAQs' />
        <AsideMenuItem isMinified={isMinified} to='/logout' title='Logout' />

        {/* <Route path='logout' element={<Logout />} /> */}
      </AsideMenuItemWithSubMain>
    </>
  )
}

export {AsideMenuMain}
