import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_ePulse/layout/core'
import '../../../App.css'
import {DashboardPage} from '../../modules/dashboard'

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
