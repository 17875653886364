/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap'
// import {toast} from 'react-toastify'
// import {ToastMessageContainer} from '../../../../../_ePulse/layout/components/ToastMessageContainer'

const companySchema = Yup.object().shape({
  companyName: Yup.string().email().required('Company Name is required'),
})

const initialValues = {
  companyName: '',
}

export function QuestionModal(props: any) {
  const [loader, setLoader] = useState(false)
  const {modalState} = props

  const formik = useFormik({
    initialValues,
    validationSchema: companySchema,
    onSubmit: async (values) => {
      try {
        // setLoader(true)

        setLoader(false)
        props.closeModel()
        // toast.success(success.ADMIN_CREATED)
      } catch (error) {
        setLoader(false)
      }
    },
  })

  return (
    <div>
      {/* <ToastMessageContainer /> */}
      <Modal
        show={modalState}
        dialogClassName='ePulse-modal ePulse-modal-40w custom-modal-width'
        onHide={() => props.closeModel()}
        backdrop='static'
      >
        {/* <Modal.Header closeButton>
          <span>Company Information</span>
        </Modal.Header> */}
        <Modal.Body className='container'></Modal.Body>
        <div className='ePulse-modal-content'>
          <form onSubmit={formik.handleSubmit}>
            <div className='custom-modal d-flex '>
              <h2 className='modal-heading'>
                Do you transport goods to a distribution warehouse before customer delivery?
              </h2>
              <div className='d-grid gap-4 col-10 mx-auto'>
                <button className='btn main-btn' type='button'>
                  Yes, I have
                </button>
                <button className='btn outline-btn' type='button'>
                  No, I don't have
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  )
}
