import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ToastContainer} from 'react-toastify'
// import {ReactQueryDevtools} from 'react-query/devtools'

// Apps
import {EpulseI18nProvider} from './_ePulse/i18n/Epulsei18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_ePulse/assets/css/style.rtl.css'
 **/
import './_ePulse/assets/sass/plugins.scss'
import './_ePulse/assets/sass/style.scss'
import './_ePulse/assets/sass/style.react.scss'
import 'react-toastify/dist/ReactToastify.css'
import './style.scss'

import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import {ThemeModeProvider} from './_ePulse/partials/layout/theme-mode/ThemeModeProvider'
import {Provider} from 'react-redux'
import store, {persistor} from './setup/redux/Store'
import {PersistGate} from 'redux-persist/integration/react'

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 
 *
 * @see https://github.com/axios/axios#interceptors
 */
setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <EpulseI18nProvider>
        <Provider store={store}>
          <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
            <ThemeModeProvider>
              {/* <AuthProvider> */}
              <AppRoutes />
              {/* </AuthProvider> */}
            </ThemeModeProvider>
          </PersistGate>
        </Provider>
      </EpulseI18nProvider>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  )
}
