import {useIntl} from 'react-intl'
import '../../App.css'
import {PageTitle} from '../../_ePulse/layout/core'
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Paper,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const FAQData = [
  {
    question: 'What is Lorem Ipsum?',
    answer: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  },
  {
    question: 'Why do we use it?',
    answer:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
  },
  {
    question: 'Where does it come from?',
    answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text.',
  },
  {
    question: 'Where can I get some?',
    answer:
      'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.',
  },
  {
    question: 'Why is Lorem Ipsum used?',
    answer:
      'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
  },
  {
    question: 'Is it safe to use Lorem Ipsum?',
    answer:
      'Yes, it is safe to use Lorem Ipsum, as it is simply dummy text of the printing and typesetting industry.',
  },
]

const FAQ = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper elevation={3} style={{padding: '20px'}}>
          <Typography variant='h4'>Frequently Asked Questions (FAQ)</Typography>
          <Divider style={{margin: '10px 0'}} />
          {/* Render FAQ items */}
          {FAQData.map((item, index) => (
            <Accordion key={index} style={{margin: '10px 0'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='subtitle1'>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant='body1'>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Paper>
      </Grid>
    </Grid>
  )
}

const FAQPage = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.FAQ'})}</PageTitle>
      <FAQ />
    </>
  )
}

export {FAQPage}
