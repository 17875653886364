// useMutationApi.js

import {useMutation} from '@apollo/client'

const useMutationApi = (mutation: any) => {
  const [createDataMutation, {loading, error, data}]: any = useMutation(mutation)

  const createData = async (input: any) => {
    try {
      console.log('MIDDLEWARE')
      const response = await createDataMutation({
        variables: input,
      })

      return response
    } catch (error: any) {
      console.error('Mutation error:', error)
      // throw error
    }
  }

  return {
    createData,
    loading,
    error,
    data,
  }
}

export default useMutationApi
