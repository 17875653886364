import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import '../../../../App.css'
import {useQuery, gql, useMutation} from '@apollo/client'
import {VERIFY_USER_MUTATION} from '../../../GraphQLQueries/Auth'
import useMutationApi from '../../../../hooks/useMutation'
import {toast} from 'react-toastify'

var ObjectID = require('bson-objectid')

const initialValues = {
  // token: '',
  password: '',
  confirmPassword: '',
}

const verifyUserSchema = Yup.object().shape({
  // token: Yup.string().required('Token is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string().required('Confirm Password is required'),
})

export function VerifyUser() {
  const navigate = useNavigate()
  const location = useLocation()
  const [loader, setLoader] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [hasErrorsmsg, setHasErrorsmsg] = useState<any>('')
  // const location = useLocation()
  // const {email}: any = location.state

  const {createData, loading, error, data} = useMutationApi(VERIFY_USER_MUTATION)

  useEffect(() => {
    if (data) {
      navigate(`/auth`)
    }
  }, [data])

  const formik = useFormik({
    initialValues,
    validationSchema: verifyUserSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoader(true)

      if (values.password !== values.confirmPassword) {
        setHasErrors(true)
        setHasErrorsmsg('Password & Confirm Password are not same')
        setLoader(false)
      } else {
        try {
          const token = new URLSearchParams(window.location.search).get('token')
          console.log(token, 'tttttkkktktktk')
          // console.log(typeof ObjectID(values.token))
          await createData({
            token: ObjectID(token),
            password: values.password,
            confirmPassword: values.confirmPassword,
          })
          setLoader(false)
          toast.success('Successfully verified user')
          navigate(`/auth`)
        } catch (error: any) {
          console.log('ERROR......', error)
          setHasErrorsmsg(error?.message)
          setHasErrors(true)
          setLoader(false)
          setSubmitting(false)
        }
      }
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3 form-heading'>Enter Your Password</h1>
        </div>

        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{hasErrorsmsg}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          {/* <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Token</label>
            <input
              placeholder=''
              {...formik.getFieldProps('token')}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
            {formik.touched.token && formik.errors.token && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.token}</span>
                </div>
              </div>
            )}
          </div> */}

          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Password</label>
            <input
              type='password'
              placeholder=''
              {...formik.getFieldProps('password')}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>

          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>Confirm Password</label>
            <input
              type='password'
              placeholder=''
              {...formik.getFieldProps('confirmPassword')}
              className={clsx('form-control form-control-lg form-control-solid')}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.confirmPassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
          >
            {!loader && <span className='indicator-label'>Update Password</span>}
            {loader && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>

        {/* end::Form group */}
      </form>
    </>
  )
}
