import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_ePulse/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_ePulse/assets/ts/_utils'
import {WithChildren} from '../../_ePulse/helpers'
import {UpdatePassword} from '../modules/auth/components/UpdatePassword'
import {CompanyInfoPage} from '../pages/CompanyInfoPage'
import {FAQPage} from '../pages/FAQPage'
import {ResetAccount} from '../pages/ResetAccount'

const PrivateRoutes = () => {
  const CarbonCalculator = lazy(() => import('../modules/calculator/CarbonCalculator'))
  const CarbonOffset = lazy(() => import('../modules/offset/CarbonOffset'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='updatePassword' element={<UpdatePassword />} />

        {/* Lazy Modules */}
        <Route
          path='calculator/*'
          element={
            <SuspensedView>
              <CarbonCalculator />
            </SuspensedView>
          }
        />
        <Route
          path='offset/*'
          element={
            <SuspensedView>
              <CarbonOffset />
            </SuspensedView>
          }
        />
        <Route
          path='reset-account'
          element={
            <SuspensedView>
              <ResetAccount />
            </SuspensedView>
          }
        />
        <Route
          path='company-info'
          element={
            <SuspensedView>
              <CompanyInfoPage />
            </SuspensedView>
          }
        />
        <Route
          path='faqs'
          element={
            <SuspensedView>
              <FAQPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
