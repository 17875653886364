import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  offsetType: null,
  step: null,
  offsetHeadInpt: null,
  headInptRefresh: false,
}

const offsetReducer = createSlice({
  name: 'offset',
  initialState,
  reducers: {
    updateOffsetType: (state, action) => {
      state.offsetType = action.payload
    },
    updateStep: (state, action) => {
      state.step = action.payload
    },
    setOffsetHeadInpt: (state, action) => {
      state.offsetHeadInpt = action.payload
    },

    reset: () => ({...initialState}),
  },
})

export const actionsOffset = offsetReducer.actions
export default offsetReducer.reducer
