/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {useAuth} from '../core/Auth'
import {useDispatch} from 'react-redux'
import {actionsUsers} from '../../../../setup/redux/rootReducer'
import {LOGIN_MUTATION} from '../../../GraphQLQueries/Auth'
import useMutationApi from '../../../../hooks/useMutation'
import {actionsCompany} from '../../../../setup/redux/reducers/companyReducer'
import {actionsCalculator} from '../../../../setup/redux/reducers/calculatorReducer'
import {toast} from 'react-toastify'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const {saveAuth} = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setStatus(false)
      setLoader(true)
      try {
        const email = values.email
        const password = values.password

        await createData({
          email,
          password,
        })

        setLoader(false)
      } catch (error: any) {
        saveAuth(undefined)
        setSubmitting(false)
        setLoader(false)
        setStatus(error?.networkError?.result?.errors[0]?.message || error?.message)
      }
    },
  })

  const {createData, /* loading, */ error, data} = useMutationApi(LOGIN_MUTATION)

  useEffect(() => {
    if (data) {
      console.log(data, '- data login - ')
      dispatch(actionsUsers.updateAuth(data.loginUser))
      toast.success('Login Successfully')
      navigate('/dashboard')
    }
    if (error) {
      saveAuth(undefined)
      setLoader(false)
      toast.error(error?.networkError?.result?.errors[0]?.message || error?.message)
    }
  }, [data, error])

  useEffect(() => {
    dispatch(actionsCompany.reset())
    dispatch(actionsCalculator.reset())
  }, [])

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3 form-heading'>Login to your account</h1>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <label className='form-label'>Email</label>
      <div className='form-group'>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span className='form-error' role='alert'>
              {formik.errors.email}
            </span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex justify-content-between'>
        <div className='flex-item'>
          <label className='form-label'>Password</label>
        </div>
        <div className='flex-item'>
          <Link to='/auth/forgot-password'>Forgot ?</Link>
        </div>
      </div>
      <div className='form-group'>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className='form-error' role='alert'>
                {formik.errors.password}
              </span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg  w-100 mb-5'
          disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
        >
          {!loader && <span className='indicator-label'>Login now</span>}
          {loader && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
