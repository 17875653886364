/* eslint-disable import/no-anonymous-default-export */
// reducer is function who has access for state& action
// eslint-disable-next-line no-unused-vars
import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  auth: null,
  isSignUp: false,
}

const userReducer = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateAuth: (state, action) => {
      state.auth = action.payload
    },
    logout: (state) => {
      state.auth = null
    },
    setSignUp: (state, action) => {
      state.isSignUp = action.payload
    },
    reset: () => ({...initialState}),
  },
})
export const actionsUsers = userReducer.actions
export default userReducer.reducer
