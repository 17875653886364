import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import clsx from 'clsx'
import {checkIsActive, KTSVG, toAbsoluteUrl} from '../../../helpers'
import {AddPatientModal} from '../../../../app/modals/addNewPatients'
import {AddStaffModal} from '../../../../app/modals/addNewStaff'
import {AddNewStaffLink} from '../../../../app/modals/addNewStaffLink'
import {ConsultationTiming} from '../../../../app/modals/consultationTiming'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  modal?: string
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  modal,
}) => {
  const {pathname} = useLocation()
  const [modalState, setModalState] = useState(false)
  const [consulting_timingmodalState, setconsulting_timingModalState] = useState(false)
  const [consulting_feesmodalState, setconsulting_feesModalState] = useState(false)

  return (
    <div className='menu-item me-lg-4'>
      {modalState && (
        <AddPatientModal
          modalState={modalState}
          setModalState={(value: boolean) => setModalState(value)}
        />
      )}
      {consulting_timingmodalState && (
        <AddStaffModal
          modalState={consulting_timingmodalState}
          setModalState={(value: boolean) => setconsulting_feesModalState(value)}
        />
        // <ConsultationTiming
        //   modalState={consulting_timingmodalState}
        //   setModalState={(value: boolean) => setconsulting_timingModalState(value)}
        // />
      )}

      {consulting_feesmodalState && (
        <AddNewStaffLink
          modalState={consulting_feesmodalState}
          setModalState={(value: boolean) => setconsulting_feesModalState(value)}
        />
      )}

      {modal ? (
        <>
          <button
            className={clsx('menu-link py-2 bg-white border-0', {
              active: checkIsActive(pathname, to),
            })}
            onClick={() =>
              modal === 'addPatientModal'
                ? setModalState(!modalState)
                : modal === 'consulting_timing'
                ? setconsulting_timingModalState(!consulting_timingmodalState)
                : setconsulting_feesModalState(!consulting_feesmodalState)
            }
          >
            {/* {hasBullet && (
              <span className='menu-bullet'>
                <span className='bullet bullet-dot'></span>
              </span>
            )} */}

            {icon && (
              <div className=' me-5'>
                <img src={toAbsoluteUrl(icon)} alt='' />
              </div>
            )}
            <span className='menu-title fs-7 fw-bold text-black'>{title}</span>
            {hasArrow && <span className='menu-arrow'></span>}
          </button>
        </>
      ) : (
        <Link
          className={clsx('menu-link py-2 bg-white', {
            active: checkIsActive(pathname, to),
          })}
          to={to}
        >
          {/* {hasBullet && (
            <span className='menu-bullet'>
              <span className='bullet bullet-dot'></span>
            </span>
          )} */}

          {icon && (
            <div className=' me-5'>
              <img src={toAbsoluteUrl(icon)} alt='' />
            </div>
          )}
          <span className='menu-title fs-7 fw-bold text-black'>{title}</span>
          {hasArrow && <span className='menu-arrow'></span>}
        </Link>
      )}
    </div>
  )
}

export {MenuItem}
