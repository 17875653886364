import {Modal} from 'react-bootstrap'
import {useEffect, useState} from 'react'
import {RESET_COMPANY_MUTATION} from '../GraphQLQueries/Company'
import useMutationApi from '../../hooks/useMutation'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {actionsCalculator} from '../../setup/redux/reducers/calculatorReducer'
import {actionsCompany} from '../../setup/redux/reducers/companyReducer'
import {actionsOffset} from '../../setup/redux/reducers/offsetReducer'

const ConfirmModal = (props: any) => {
  const {modalState, loader} = props

  return (
    <Modal
      show={modalState}
      dialogClassName='ePulse-modal ePulse-modal-40w custom-modal-width modal-dialog-centered'
      onHide={() => props.closeModel()}
      backdrop='static'
    >
      <Modal.Body className='container' />

      <div className='custom-modal d-flex'>
        <h1 className='modal-heading '>
          Reset Confirmation!
          <span className='d-block  fw-normal mt-8' style={{fontSize: '18px', lineHeight: '30px'}}>
            Are you sure you want to reset all company and calculation data?
          </span>
        </h1>
        <div className='mx-auto' style={{display: 'flex' /* marginLeft: 'auto' */}}>
          <button className='btn main-btn mx-5' type='submit' onClick={() => props.closeModel()}>
            Cancel
          </button>
          <button
            className='btn outline-btn-danger'
            style={{borderColor: 'red'}}
            type='button'
            onClick={() => props.resetConfirm()}
          >
            {!loader && <span className='indicator-label'>Confirm</span>}
            {loader && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

const ResetAccount = () => {
  const [confirmModal, setConfirmModal] = useState(true)
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    createData: resetCompanyData,
    error: resetErr,
    data: resetRes,
  } = useMutationApi(RESET_COMPANY_MUTATION)

  useEffect(() => {
    if (resetRes && resetRes.resetCompanyData) {
      toast.success(resetRes.resetCompanyData)
      dispatch(actionsCalculator.reset())
      dispatch(actionsCompany.reset())
      dispatch(actionsOffset.reset())

      navigate('/dashboard')
    }
    if (resetErr && resetErr.networkError?.result?.errors[0]?.message) {
      toast.error(resetErr.networkError?.result?.errors[0]?.message)
    }
  }, [resetRes, resetErr])

  const resetConfirm = async () => {
    setLoader(true)
    await resetCompanyData({})
  }

  return (
    <>
      <ConfirmModal
        modalState={confirmModal}
        closeModel={() => {
          setConfirmModal(false)
          navigate('/dashboard')
        }}
        resetConfirm={() => resetConfirm()}
        loader={loader}
      />
    </>
  )
}

export {ResetAccount}
