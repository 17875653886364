import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  step: null,
  subTotal: 0,
  stages: null,
  stageRefresh: false,
}

const calculatorReducer = createSlice({
  name: 'calculator',
  initialState,
  reducers: {
    updateStep: (state, action) => {
      state.step = action.payload
    },
    updateSubTotal: (state, action) => {
      state.subTotal = action.payload
    },
    updateStages: (state, action) => {
      state.stages = action.payload
    },
    setStageRefresh: (state, action) => {
      state.stageRefresh = action.payload
    },
    reset: () => ({...initialState}),
  },
})

export const actionsCalculator = calculatorReducer.actions
export default calculatorReducer.reducer
