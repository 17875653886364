import React, {useEffect, useState} from 'react'
import {useLocation} from 'react-router'
import {useSelector} from 'react-redux'
import {CompanyModel} from './models/CompanyModel'
import {DomainModel} from './models/DomainModel'
import {QuestionModal} from './models/QuestionModal'
import {useNavigate} from 'react-router-dom'
import {CalculationComplete} from './models/CalculationComplete'

type Props = {
  isMinified?: boolean
}

const DashboardPage: React.FC<Props> = ({isMinified}) => {
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const [domainModal, setDomainModal] = useState(false)
  const [companyModal, setCompanyModal] = useState(false)
  const [questionModal, setQuestionModal] = useState(false)
  const [calculationComplete, setCalculationComplete] = useState(false)

  const {company, auth, calculator}: any = useSelector((state) => {
    return state
  })

  useEffect(() => {
    setTimeout(() => {}, 50)
  }, [pathname])

  useEffect(() => {
    if (!(company?.industryDomain || auth?.auth?.isIndustrySelected)) {
      setDomainModal(true)
    } else if (!(company?.company || auth?.auth?.isCompanyProfileCompleted)) {
      setCompanyModal(true)
    } else {
      if (calculator && calculator.stages) {
        navigate('/calculator' + calculator.stages[0].path)
      } /* else {
        navigate('/calculator/at-port')
      } */
    }
  }, [company, company?.industryDomain, calculator?.stages, auth])

  return (
    <>
      <div>
        {/* <p>dashboard page</p>
        <button onClick={() => setDomainModal(true)}>Domain Model</button>
        <button onClick={() => setCompanyModal(true)}>Company Model</button>
        <button onClick={() => setQuestionModal(true)}>Question Model</button> */}
        {/* <button onClick={() => setQuestionModal(true)}>Question Model</button>
        <button onClick={() => setCalculationComplete(true)}>Calculation Model</button> */}
      </div>

      {domainModal && (
        <DomainModel modalState={domainModal} closeModel={() => setDomainModal(false)} />
      )}
      {companyModal && (
        <CompanyModel
          modalState={companyModal}
          closeModel={() => setCompanyModal(false)}
          setDomainModal={(b: boolean) => setDomainModal(b)}
        />
      )}
      {questionModal && (
        <QuestionModal modalState={questionModal} closeModel={() => setCompanyModal(false)} />
      )}
      {calculationComplete && (
        <CalculationComplete
          modalState={calculationComplete}
          closeModel={() => setCompanyModal(false)}
        />
      )}
    </>
  )
}

export {DashboardPage}
