/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap'
import {useDispatch} from 'react-redux'
import {actionsCompany} from '../../../../setup/redux/reducers/companyReducer'
// import {toast} from 'react-toastify'
// import {ToastMessageContainer} from '../../../../../_ePulse/layout/components/ToastMessageContainer'
import {toAbsoluteUrl} from '../../../../_ePulse/helpers'
// import IconFood from '/images/food.svg'

const companySchema = Yup.object().shape({
  industryDomain: Yup.string().required('Industry Domain is required'),
})

const initialValues = {
  industryDomain: '',
}

export function CalculationComplete(props: any) {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const {modalState} = props

  const formik = useFormik({
    initialValues,
    validationSchema: companySchema,
    onSubmit: async (values) => {
      setLoader(true)
      try {
        const industryDomain = values.industryDomain

        if (industryDomain) {
          dispatch(actionsCompany.updateDomain(industryDomain))
          props.closeModel()
        }

        setLoader(false)
      } catch (error) {
        setLoader(false)
      }
    },
  })

  return (
    <div>
      {/* <ToastMessageContainer /> */}
      <Modal
        show={modalState}
        dialogClassName='ePulse-modal ePulse-modal-40w custom-modal-width'
        onHide={() => props.closeModel()}
        backdrop='static'
      >
        {/* <Modal.Header closeButton>
          <span className='fs-3 fw-bold'>Choose Your Industry Domain</span>
        </Modal.Header> */}
        <Modal.Body className='container' />
        <div className='ePulse-modal-content'>
          <form onSubmit={formik.handleSubmit}>
            <div className='custom-modal d-flex '>
              <div className='carbon-icon mb-5'>
                <img src={toAbsoluteUrl('images/co2.svg')} alt='co2-icon' className='w-75' />
              </div>
              <h2 className='modal-heading fs-1'>
                Your Carbon Emission Calculations is Completed !
                <span className='d-block text-primary fw-normal mt-5'>
                  Continue to Carbon Offset
                </span>
              </h2>
              <div className='d-grid gap-4 col-10 mx-auto'>
                <button className='btn main-btn' type='button'>
                  Calculate Carbon Offset by Capacity
                </button>
                <button className='btn outline-btn' type='button'>
                  Calculate Carbon Offset by Percentage
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  )
}
