/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Link, Outlet, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {NewPassword} from './components/NewPassword'
import {toAbsoluteUrl} from '../../../_ePulse/helpers'
import {VerifyUser} from './components/VerifyUser'
import TelegramIcon from '@mui/icons-material/Telegram'
import {useDispatch, useSelector} from 'react-redux'
import {actionsUsers} from '../../../setup/redux/rootReducer'

const AuthLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {auth}: any = useSelector((state) => {
    return state
  })

  console.log(auth, 'loginloginloginlogin')

  useEffect(() => {
    document.body.style.backgroundImage = 'none'

    return () => {}
  }, [])

  const sentOkay = () => {
    dispatch(actionsUsers.setSignUp(false))
    navigate(`/auth/login`)
  }

  return (
    <div className='d-flex h-100 justify-content-between h-100'>
      <div
        className='login-banner p-5 col-md-6'
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/images/background1.png')})`,
          boxShadow: 'inset 0 0 0 1000px rgba(44,43,43,.6)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
        }}
      >
        <div className='login-banner-text'>
          <h2>Stay Informed, Take Action!</h2>
          <p>
            N-Zero is more than just a platform, it's a movement towards a sustainable and resilient
            future. By staying informed and taking action, each one of us can contribute to a world
            with reduced carbon emissions and a healthier environment for all. Join us in making a
            positive difference today!
          </p>
        </div>
      </div>

      {/* begin::Content */}
      {location.pathname === '/auth/registration' && auth && auth.isSignUp ? (
        <div className='login-section col-12 col-md-6'>
          <div
            className='d-flex h-100 flex-column align-items-center justify-content-center'
            style={{
              backgroundColor: '#D9D9D9',
              padding: '72px 16px',
            }}
          >
            <div
              className='d-flex flex-column w-100 align-items-center bg-body px-20 pt-10'
              style={{
                borderRadius: '20px',
                maxWidth: '540px',
              }}
            >
              <a href='#' className='mb-10 mt-4'>
                <TelegramIcon style={{fontSize: '5rem', color: '#00a84d'}} />
              </a>
              <div className='text-center mb-10'>
                <h1 className='text-dark mb-8'>Email Sent !</h1>
                <span
                  style={{
                    fontSize: '14px',
                  }}
                >
                  Kindly check your inbox in order to verify the account
                </span>
              </div>

              <div className='text-center' style={{width: '90%'}}>
                <button
                  type='button'
                  onClick={() => sentOkay()}
                  id='kt_sign_in_submit'
                  className='btn btn-lg w-100 mb-15'
                >
                  <span className='indicator-label'>Okay</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='login-section col-12 col-md-6'>
          <div
            className='d-flex h-100 flex-column align-items-center justify-content-center'
            style={{
              backgroundColor: '#D9D9D9',
              padding: '72px 16px',
            }}
          >
            <div
              className='d-flex flex-column w-100 align-items-center bg-body px-20 pt-10'
              style={{
                borderRadius: '20px',
                maxWidth: '540px',
              }}
            >
              <a href='#' className='mb-10 mt-4'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/images/logo.svg')}
                  className='theme-dark-show h-25px'
                />
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/images/logo.svg')}
                  className='theme-light-show h-35px'
                ></img>
              </a>
              <Outlet />
              <div className='d-flex flex-center flex-column-auto pt-5 pb-10 mb-10 login-footer'>
                <div className='d-flex align-items-center'>
                  {location.pathname === '/auth/registration' ? (
                    <>
                      <a href='#' className='text-muted text-hover-primary'>
                        Already Have An Account?
                      </a>

                      <Link to='/auth/login'>Log In</Link>
                    </>
                  ) : (
                    <>
                      <a href='#' className='text-muted text-hover-primary'>
                        Don't Have An Account?
                      </a>

                      <Link to='/auth/registration'>Sign Up</Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* end::Content */}
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='verify-user' element={<VerifyUser />} />
      <Route path='password' element={<NewPassword />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
