import React, {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import '../../../../App.css'
import {BrowserRouter as Router, useNavigate} from 'react-router-dom'
import {SEND_FORGOT_PASSWORD_LINK_MUTATION} from '../../../GraphQLQueries/Auth'
import useMutationApi from '../../../../hooks/useMutation'
import {toast} from 'react-toastify'
const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const {createData, error, data} = useMutationApi(SEND_FORGOT_PASSWORD_LINK_MUTATION)

  useEffect(() => {
    if (data) {
      toast.success(data?.sendForgerPasswordLink)
      navigate(
        `/auth/login` /* , {
        state: {
          email: formik.values.email,
        },
      } */
      )
    }
    if (error && error.networkError?.result?.errors[0]?.message) {
      toast.error(error.networkError?.result?.errors[0]?.message)
    }
  }, [data, error])

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoader(true)
      setStatus(false)

      try {
        await createData({
          email: values.email,
        })
        setLoader(false)
      } catch (error: any) {
        setSubmitting(false)
        setLoader(false)
        setStatus(error?.networkError?.result?.errors[0]?.message || error?.message)
      }
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3 form-heading'>Forgot Password</h1>
          {/* end::Title */}
        </div>

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}

        <div className='text-center'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg  w-100 mb-5'
            disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
          >
            {!loader && <span className='indicator-label'>Reset Password</span>}
            {loader && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
