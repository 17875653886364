import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {actionsUsers} from '../../../setup/redux/rootReducer'
import useMutationApi from '../../../hooks/useMutation'
import {LOGOUT} from '../../GraphQLQueries/Auth'

export function Logout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // const {createData, loading: loading2, error, data} = useMutationApi(LOGOUT)

  const logout = async () => {
    // const response = await createData({logoutType: 'one'})

    dispatch(actionsUsers.logout())
    navigate('/', {replace: true})
  }

  useEffect(() => {
    logout()
  }, [])

  return <></>
}
