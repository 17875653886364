import {combineReducers, configureStore} from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import {persistStore, persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import calculatorReducer from './reducers/calculatorReducer'
import companyReducer from './reducers/companyReducer'
import offsetReducer from './reducers/offsetReducer'

// const store = configureStore({
//   reducer: rootReducer,
// })

const reducers = combineReducers({
  auth: rootReducer,
  calculator: calculatorReducer,
  company: companyReducer,
  offset: offsetReducer,
})

const persistConfig = {
  key: 'root',
  storage,
  // whitelist: ['auth'],
}

const persistedReducer = persistReducer(persistConfig, reducers)
// const authReducer = persistReducer(persistConfig, rootReducer)
// const calcReducer = persistReducer(persistConfig, calculatorReducer)

const store = configureStore({
  reducer: persistedReducer,
  // reducer: {
  //   auth: authReducer,
  //   calculator: calcReducer,
  // },
})

const persistor = persistStore(store)

export {persistor}

export default store
