/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ToastMessageContainer = (props) => {
  // toast("Wow so easy!");

  return (
    <React.Fragment>
      <ToastContainer />
    </React.Fragment>
  )
}

export {ToastMessageContainer}
