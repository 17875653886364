import {useEffect, useState} from 'react'
import {Field, useFormik, FormikProvider} from 'formik'
import * as Yup from 'yup'
import {Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import useMutationApi from '../../../../hooks/useMutation'
import {RESET_COMPANY_MUTATION, UPSERT_COMPANY_MUTATION} from '../../../GraphQLQueries/Company'
import {useDispatch, useSelector} from 'react-redux'
import {actionsCompany} from '../../../../setup/redux/reducers/companyReducer'
import {toast} from 'react-toastify'
import {PRODUCT_LIST} from '../../../../data'
import _, {isError} from 'lodash'
import {MultiSelect} from '../../../components'
import {getYears} from '../../../../data/common'
import {actionsCalculator} from '../../../../setup/redux/reducers/calculatorReducer'
import {actionsOffset} from '../../../../setup/redux/reducers/offsetReducer'

const companySchema = Yup.object().shape({
  companyName: Yup.string().required('Company Name is required'),
  companyLocation: Yup.string().nullable(),
  annualProductionRate: Yup.string().nullable(),
  assessmentYear: Yup.string().required('Assessment Year is required'),
  businessDescription: Yup.string(),
  visionMission: Yup.string(),
  // products: Yup.string().nullable(),
  products: Yup.array().of(Yup.string()).nullable(),
  carbonOffsetGoals: Yup.number().min(1).max(100).required('Offset goal is required'),
  inNumberOfYear: Yup.number(),
})

const initialValues = {
  companyName: '',
  companyLocation: '',
  annualProductionRate: '',
  assessmentYear: '',
  businessDescription: '',
  visionMission: '',
  products: '',
  carbonOffsetGoals: '',
  inNumberOfYear: 1,
}
const years = getYears(15)

export function CompanyModel(props: any) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)
  const [dataObj, setDataObj] = useState<any>()
  const [products, setProducts] = useState<any>([])
  const {modalState} = props

  const [wordCountBusinessDescription, setWordCountBusinessDescription] = useState('0-150 words')
  const [wordCountVisionMission, setWordCountVisionMission] = useState('0-150 words')
  const [isBusinessDescriptionError, setBusinessDescriptionIsError] = useState(false)
  const [isVisionMissionError, setVisionMissionIsError] = useState(false)
  const [errorMessage] = useState('More than 150 words inserted')

  const {company}: any = useSelector((state) => {
    return state
  })

  const {
    createData: addCompany,
    loading: addLoading,
    error: addError,
    data: addRes,
  } = useMutationApi(UPSERT_COMPANY_MUTATION)

  const {
    createData: resetCompanyData,
    error: resetErr,
    data: resetRes,
  } = useMutationApi(RESET_COMPANY_MUTATION)

  useEffect(() => {
    if (PRODUCT_LIST && company && company.industryDomain) {
      let productsFiltered = _.filter(
        PRODUCT_LIST,
        (v) => v.category === company.industryDomain.industryCategory
      )

      setProducts(productsFiltered)
    }
  }, [company?.industryDomain, PRODUCT_LIST])

  const formik = useFormik({
    initialValues,
    validationSchema: companySchema,
    onSubmit: async (values) => {
      try {
        let companyObj = {
          companyName: values.companyName,
          companyLocation: values.companyLocation || '',
          annualProductionRate: values.annualProductionRate || '',
          assessmentYear: values.assessmentYear,
          businessDescription: values.businessDescription,
          visionMission: values.visionMission,
          products: values.products,
          carbonOffsetGoals: values.carbonOffsetGoals && Number(values.carbonOffsetGoals),
          inNumberOfYear: values.inNumberOfYear && Number(values.inNumberOfYear),
        }

        setDataObj(companyObj)

        await addCompany(companyObj)

        setLoader(false)
      } catch (error) {
        setLoader(false)
      }
    },
  })

  useEffect(() => {
    if (addRes && addRes.createOrUpdateCompanyProfile) {
      toast.success(addRes.createOrUpdateCompanyProfile || 'Company Info Saved Successfully')
      dispatch(actionsCompany.updateCompany(dataObj))
      props.closeModel()
      navigate('/calculator/at-port')
    } else if (addError?.networkError?.result?.errors[0]?.message) {
      toast.error(addError?.networkError?.result?.errors[0]?.message /*  || addError?.message */)
    }
  }, [addError, addRes])

  useEffect(() => {
    const updateBusinessDescriptionWordCount = () => {
      const wordCount = (formik.values.businessDescription || '')
        .split(/\s+/)
        .filter(Boolean).length
      const remainingWords = 150 - wordCount

      setWordCountBusinessDescription(
        `(${remainingWords >= 0 ? remainingWords : 0} words) remaining`
      )

      if (remainingWords <= 0) {
        setBusinessDescriptionIsError(true)
      } else {
        setBusinessDescriptionIsError(false)
      }
    }

    updateBusinessDescriptionWordCount()
  }, [formik.values.businessDescription])

  useEffect(() => {
    const updateVisionMissionWordCount = () => {
      const remainingWords = 151 - (formik.values.visionMission || '').split(/\s+/).length
      setWordCountVisionMission(`(${remainingWords} words) remaining`)

      if (remainingWords <= 0) {
        setVisionMissionIsError(true)
      } else {
        setVisionMissionIsError(false)
      }
    }

    updateVisionMissionWordCount()
  }, [formik.values.visionMission])

  useEffect(() => {
    if (resetRes && resetRes.resetCompanyData) {
      toast.success(resetRes.resetCompanyData)
      dispatch(actionsCalculator.reset())
      dispatch(actionsCompany.reset())
      dispatch(actionsOffset.reset())

      navigate('/dashboard')
      props.setDomainModal(true)
      props.closeModel()
    }
    if (resetErr && resetErr.networkError?.result?.errors[0]?.message) {
      toast.error(resetErr.networkError?.result?.errors[0]?.message)
    }
  }, [resetRes, resetErr])

  const resetByBack = async () => {
    // setLoader(true)
    await resetCompanyData({})
  }

  return (
    <div>
      <Modal
        show={modalState}
        dialogClassName='ePulse-modal ePulse-modal-70w custom-modal-width modal-dialog-centered'
        onHide={() => props.closeModel()}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Body className='container'></Modal.Body>
        <FormikProvider value={formik}>
          <div className='ePulse-modal-content'>
            <form onSubmit={formik.handleSubmit} className='form'>
              <div className='company-info-form bg-white'>
                <div className='info-form-heading mb-5'>
                  <h2>
                    Company Information
                    <span className='small-text text-primary d-block mt-3 mt-md-0 d-md-inline'>
                      {` (${company?.industryDomain?.industryName})`}
                    </span>
                  </h2>
                </div>
                <div className='info-form-upper-inputs upper-inputs d-flex justify-content-between'>
                  <div className='me-8 w-100 mb-3'>
                    <label htmlFor='companyName' className='form-label'>
                      Company Name
                    </label>
                    <input
                      type='text'
                      {...formik.getFieldProps('companyName')}
                      className='form-control'
                      id='companyName'
                      placeholder=''
                    />
                    {formik.touched.companyName && formik.errors.companyName && (
                      <div className='fv-plugins-message-container'>
                        <span className='form-error' role='alert'>
                          {formik.errors.companyName}
                        </span>
                      </div>
                    )}
                  </div>

                  {company?.industryDomain?.industryName === 'Food Processing' ? (
                    <div className='me-8 w-100 mb-3'>
                      <label htmlFor='annualProductionRate' className='form-label'>
                        Annual production rate
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('annualProductionRate')}
                        className='form-control'
                        id='annualProductionRate'
                        placeholder=''
                      />
                      {formik.touched.annualProductionRate && formik.errors.annualProductionRate && (
                        <div className='fv-plugins-message-container'>
                          <span className='form-error' role='alert'>
                            {formik.errors.annualProductionRate}
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className='me-8 w-100 mb-3'>
                      <label htmlFor='companyLocation' className='form-label'>
                        Location
                      </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('companyLocation')}
                        className='form-control'
                        id='companyLocation'
                        placeholder=''
                      />
                      {formik.touched.companyLocation && formik.errors.companyLocation && (
                        <div className='fv-plugins-message-container'>
                          <span className='form-error' role='alert'>
                            {formik.errors.companyLocation}
                          </span>
                        </div>
                      )}
                    </div>
                  )}

                  <div className='w-100 mb-3'>
                    <label htmlFor='selectYear' className='form-label'>
                      Assessment Year
                    </label>
                    <select className='form-select' {...formik.getFieldProps('assessmentYear')}>
                      <option value=''>Select Year</option>
                      {years &&
                        years.map((val, idx) => (
                          <option key={'year_' + val} value={val}>
                            {val}
                          </option>
                        ))}
                    </select>
                    {formik.touched.assessmentYear && formik.errors.assessmentYear && (
                      <div className='fv-plugins-message-container'>
                        <span className='form-error' role='alert'>
                          {formik.errors.assessmentYear}
                        </span>
                      </div>
                    )}
                    <span className='small-text mt-2 d-block text-primary'>
                      {formik.values.assessmentYear &&
                        `(1 Jan ${formik.values.assessmentYear} - 31 Dec ${formik.values.assessmentYear})`}
                    </span>
                  </div>
                </div>

                <div className='info-form-middle-inputs mb-8'>
                  <div className='business-desc mb-8' style={{position: 'relative'}}>
                    <label htmlFor='businessDescription' className='form-label'>
                      Business Description <span className='small-text'> (optional) </span>
                    </label>
                    <textarea
                      className='form-control'
                      id='businessDescription'
                      placeholder='Type Here'
                      rows={4}
                      {...formik.getFieldProps('businessDescription')}
                    ></textarea>
                    {!formik.values.businessDescription && (
                      <span className='textarea__counter'>{wordCountBusinessDescription}</span>
                    )}
                    {formik.values.businessDescription && (
                      <span className='textarea__counter--outer'>
                        {wordCountBusinessDescription}
                      </span>
                    )}
                    {isBusinessDescriptionError && (
                      <span className='textarea_error_message'>{errorMessage}</span>
                    )}

                    {formik.touched.businessDescription && formik.errors.businessDescription && (
                      <div className='fv-plugins-message-container'>
                        <span className='form-error' role='alert'>
                          {formik.errors.businessDescription}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className='vision' style={{position: 'relative'}}>
                    <label htmlFor='visionMission' className='form-label'>
                      Vision & Mission <span className='small-text'> (optional) </span>
                    </label>
                    <textarea
                      className='form-control'
                      id='visionMission'
                      placeholder='Type Here'
                      rows={4}
                      {...formik.getFieldProps('visionMission')}
                    ></textarea>
                    {!formik.values.visionMission && (
                      <span className='textarea__counter'>{wordCountVisionMission}</span>
                    )}
                    {formik.values.visionMission && (
                      <span className='textarea__counter--outer'>{wordCountVisionMission}</span>
                    )}
                    {isVisionMissionError && (
                      <span className='textarea_error_message'>{errorMessage}</span>
                    )}

                    {formik.touched.visionMission && formik.errors.visionMission && (
                      <div className='fv-plugins-message-container'>
                        <span className='form-error' role='alert'>
                          {formik.errors.visionMission}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className='info-form-bottom-inputs bottom-input d-flex justify-content-between'>
                  <div className='w-100 me-8 mb-3'>
                    {company?.industryDomain?.industryName === 'Food Processing' ? (
                      <label htmlFor='products' className='form-label'>
                        Name/s of food product
                      </label>
                    ) : (
                      <label htmlFor='products' className='form-label'>
                        Product(s)
                      </label>
                    )}
                    <Field
                      // className='form-select'
                      {...formik.getFieldProps('products')}
                      options={products}
                      component={MultiSelect}
                      isMulti={true}
                    />
                    {/* <select
                    className='form-select'
                    {...formik.getFieldProps('products')}
                    name='products'
                  >
                    <option value=''>Select Product</option>
                    {products &&
                      products.map((val: any, idx: any) => (
                        <option key={'prod_' + idx} value={val.value}>
                          {val.name}{' '}
                        </option>
                      ))}
                  </select> */}
                    {formik.touched.products && formik.errors.products && (
                      <div className='fv-plugins-message-container'>
                        <span className='form-error' role='alert'>
                          {formik.errors.products}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className='me-8 w-100 mb-3'>
                    <label htmlFor='carbonOffsetGoals' className='form-label'>
                      Carbon Offset Goal
                    </label>
                    <div className='input-group mb-3'>
                      <input
                        type='text'
                        className='form-control'
                        {...formik.getFieldProps('carbonOffsetGoals')}
                      />
                      <span className='input-group-text bg-white'>%</span>
                      {formik.touched.carbonOffsetGoals && formik.errors.carbonOffsetGoals && (
                        <div className='fv-plugins-message-container'>
                          <span className='form-error' role='alert'>
                            {formik.errors.carbonOffsetGoals}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className=' w-100'>
                    <label htmlFor='inNumberOfYear' className='form-label'>
                      In No. of Years
                    </label>
                    <input
                      type='text'
                      {...formik.getFieldProps('inNumberOfYear')}
                      className='form-control'
                      id='inNumberOfYear'
                      placeholder=''
                    />
                    {formik.touched.inNumberOfYear && formik.errors.inNumberOfYear && (
                      <div className='fv-plugins-message-container'>
                        <span className='form-error' role='alert'>
                          {formik.errors.inNumberOfYear}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className='bottom-buttons d-flex justify-content-end mt-8'>
                  <button
                    className='btn outline-btn me-8'
                    type='button'
                    onClick={() => {
                      resetByBack()
                    }}
                  >
                    Back
                  </button>
                  <button
                    className='btn main-btn'
                    type='submit'
                    /* onClick={() => {
                    navigate('/calculator/at-port')
                  }} */
                    disabled={
                      formik.isSubmitting ||
                      !(formik.isValid && formik.dirty) ||
                      isBusinessDescriptionError ||
                      isVisionMissionError
                    }
                  >
                    {!loader && <span className='indicator-label'>Next</span>}
                    {loader && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </FormikProvider>
      </Modal>
    </div>
  )
}
